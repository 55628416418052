import { CheckOutlined, CloseOutlined, MenuOutlined } from "@ant-design/icons";
import {
    Button,
    Divider,
    Form,
    Input,
    InputNumber,
    Select,
    Switch,
    Table,
    message,
} from "antd";
import { cloneDeep, pullAt, set } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { BlockPicker } from "react-color";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
    CopyIcon,
    DeleteIcon,
    RenderUpload,
    SlateEditor,
} from "src/components";
import { replaceCDNUrl } from "src/modules/worksheet/components/blocks/helpers";
import { v4 as uuid } from "uuid";

const splitAndUpperCase = (str: string) =>
    str
        ?.split("_")
        .filter((x) => x.length > 0)
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(" ");

const TagsTable: React.FC = ({
    setBlock,
    disableWrite,
    currentEditor,
    setCurrentEditor,
    hasMentions,
    mentionsList = [],
    value: items = [],
    schema,
}: any) => {
    const [tempConfig, setTempConfig] = useState("");

    const componentsSchema =
        typeof schema === "string" ? JSON.parse(schema) : schema;

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            let tmpBlock = cloneDeep(items);
            const dragRow = tmpBlock[dragIndex];
            tmpBlock.splice(dragIndex, 1);
            tmpBlock.splice(hoverIndex, 0, dragRow);
            setBlock(tmpBlock);
        },
        [items],
    );

    const categories = useMemo(() => {
        const catSet = new Set();
        items.forEach((element: { props: { categoryNew: { value: any } } }) => {
            let category = element.props?.categoryNew?.value;
            if (category) {
                if (typeof category === "string") catSet.add(category);
                else if (Array.isArray(category)) {
                    category.forEach((v) => catSet.add(v));
                }
            }
        });
        return Array.from(catSet);
    }, [items]);

    return (
        <div
            style={{
                padding: "20px",
            }}
        >
            <DndProvider backend={HTML5Backend}>
                <Table
                    scroll={{ y: "60vh", x: 1000 }}
                    size="small"
                    pagination={false}
                    dataSource={items?.map((v, i) => {
                        let currCount = v?.props?.currentCount?.value;
                        if (currCount === undefined || currCount === null) {
                            currCount = 1;
                        }
                        return {
                            key: i,
                            text: v?.props?.text?.value,
                            drop_value: v?.props?.drop_value?.value,
                            background_type:
                                v?.props.input_background?.value?.type ||
                                "SOLID",
                            input_background: v?.props.input_background?.value,
                            textVisible: v?.props.textVisible?.value,
                            categoryNew: v?.props.categoryNew?.value,
                            currentCount: currCount,
                        };
                    })}
                    columns={[
                        {
                            title: "Index",
                            dataIndex: "key",
                            width: 60,
                            render: (v) => (
                                <>
                                    {v + 1} <MenuOutlined />
                                </>
                            ),
                        },
                        {
                            title: "Text",
                            dataIndex: "text",
                            width: 200,
                            render: (v, data) => {
                                return (
                                    <div
                                        style={{
                                            minWidth: "150px",
                                        }}
                                    >
                                        <SlateEditor
                                            disabled={disableWrite}
                                            hasMentions={hasMentions}
                                            mentionsList={mentionsList}
                                            id={`${data.key}_tag_edit`}
                                            onChange={(value: any) => {
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock[
                                                    data?.key
                                                ].props.text.value = value;

                                                tmpBlock[
                                                    data?.key
                                                ].props.drop_value.value =
                                                    value[0]?.children[0]?.text;
                                                setBlock(tmpBlock);
                                            }}
                                            isFocused={
                                                currentEditor ===
                                                `${data.key}_tag_edit`
                                            }
                                            setEditor={(id: string) =>
                                                setCurrentEditor(id)
                                            }
                                            value={v}
                                            required={false}
                                        />
                                    </div>
                                );
                            },
                        },
                        {
                            title: "Value",
                            dataIndex: "drop_value",
                            width: 200,
                            render: (v, data) => {
                                return (
                                    <Form.Item
                                        validateStatus={!v ? "error" : ""}
                                        help={!v ? "Field cannot be empty" : ""}
                                    >
                                        <Input
                                            disabled={disableWrite}
                                            style={{
                                                minWidth: "100px",
                                            }}
                                            value={v}
                                            onChange={(e) => {
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock[
                                                    data?.key
                                                ].props.drop_value.value =
                                                    e.target.value;
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Form.Item>
                                );
                            },
                        },
                        // Category
                        {
                            title: "Categories",
                            dataIndex: "categoryNew",
                            width: 200,
                            render: (v, data) => {
                                return (
                                    <Form.Item>
                                        <Select
                                            disabled={disableWrite}
                                            style={{ width: "100%" }}
                                            placeholder="Select"
                                            mode="tags"
                                            value={
                                                typeof v === "string" ? [v] : v
                                            }
                                            onChange={(value) => {
                                                let tmpBlock = cloneDeep(items);
                                                set(
                                                    tmpBlock,
                                                    [
                                                        data?.key,
                                                        "props",
                                                        "categoryNew",
                                                        "value",
                                                    ],
                                                    value,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                            options={categories.map((i) => ({
                                                value: i,
                                                label: i,
                                            }))}
                                        />
                                    </Form.Item>
                                );
                            },
                        },

                        {
                            title: "Bg Type",
                            dataIndex: "background_type",
                            width: 160,
                            render: (v, data) => {
                                return (
                                    <Select
                                        style={{
                                            width: 120,
                                        }}
                                        disabled={disableWrite}
                                        defaultValue={v}
                                        placeholder="Background Type"
                                        onChange={(value: any) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[
                                                data?.key
                                            ].props.input_background.value.type =
                                                value;
                                            setBlock(tmpBlock);
                                        }}
                                        options={[
                                            {
                                                value: "SOLID",
                                                label: "Solid Color",
                                            },
                                            {
                                                value: "IMAGE",
                                                label: "Image",
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                        {
                            title: "Background",
                            dataIndex: "input_background",
                            width: 300,
                            render: (v, data) => {
                                return (
                                    <>
                                        {v?.type === "SOLID" && (
                                            <BlockPicker
                                                colors={[]}
                                                color={v?.color}
                                                onChangeComplete={(
                                                    color: any,
                                                ) => {
                                                    if (disableWrite) return;
                                                    const value = color.hex;
                                                    let tmpBlock =
                                                        cloneDeep(items);
                                                    tmpBlock[
                                                        data?.key
                                                    ].props.input_background.value.color =
                                                        value;
                                                    setBlock(tmpBlock);
                                                }}
                                                triangle="hide"
                                            />
                                        )}
                                        {["IMAGE", "GIF"].includes(v?.type) && (
                                            <>
                                                <RenderUpload
                                                    singleUpload={true}
                                                    disabled={disableWrite}
                                                    addExtension={true}
                                                    path="home-explore/document/"
                                                    onChangeCustom={(e) => {
                                                        const value = e?.target
                                                            ?.value
                                                            ? replaceCDNUrl(
                                                                  e?.target
                                                                      ?.value,
                                                                  e?.target
                                                                      ?.bucket,
                                                              )
                                                            : "";
                                                        let tmpBlock =
                                                            cloneDeep(items);
                                                        tmpBlock[
                                                            data?.key
                                                        ].props.input_background.value.asset =
                                                            value;
                                                        setBlock(tmpBlock);
                                                    }}
                                                    label="upload the file"
                                                    value={v?.asset}
                                                />
                                                <Input
                                                    placeholder="Add value here"
                                                    value={v?.asset}
                                                    disabled={disableWrite}
                                                    onChange={(e) => {
                                                        const value =
                                                            e.target.value;
                                                        let tmpBlock =
                                                            cloneDeep(items);
                                                        tmpBlock[
                                                            data?.key
                                                        ].props.input_background.value.asset =
                                                            value;
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            </>
                                        )}
                                    </>
                                );
                            },
                        },
                        {
                            title: "Max Drag Limit",
                            dataIndex: "currentCount",
                            width: 200,
                            render: (v, data) => {
                                return (
                                    <Form.Item
                                        validateStatus={!v ? "error" : ""}
                                        help={!v ? "Field cannot be empty" : ""}
                                    >
                                        <InputNumber
                                            disabled={disableWrite}
                                            style={{
                                                minWidth: "100px",
                                            }}
                                            value={v}
                                            onChange={(e) => {
                                                let tmpBlock = cloneDeep(items);
                                                if (
                                                    tmpBlock[data?.key].props
                                                        .currentCount ===
                                                    undefined
                                                ) {
                                                    tmpBlock[
                                                        data?.key
                                                    ].props.currentCount = {
                                                        value: e,
                                                    };
                                                } else {
                                                    tmpBlock[
                                                        data?.key
                                                    ].props.currentCount.value =
                                                        e;
                                                }
                                                setBlock(tmpBlock);
                                            }}
                                            min={0}
                                            max={100}
                                            defaultValue={1}
                                        />
                                    </Form.Item>
                                );
                            },
                        },
                        {
                            title: "Is visible?",
                            dataIndex: "textVisible",
                            width: 80,
                            render: (v, data) => {
                                return (
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={!!Number(v)}
                                        onChange={(value) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[
                                                data?.key
                                            ].props.textVisible.value = value
                                                ? 1
                                                : 0;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            title: "Actions",
                            key: "operation",
                            fixed: "right",
                            width: 100,
                            render: (v, data) => (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                    }}
                                >
                                    <CopyIcon
                                        disabled={disableWrite}
                                        isButton={true}
                                        onClick={() => {
                                            let tmpItems = cloneDeep(items);
                                            tmpItems.splice(data.key + 1, 0, {
                                                ...cloneDeep(
                                                    tmpItems[data.key],
                                                ),
                                                genericId: uuid(),
                                            });
                                            setBlock(tmpItems);
                                            message.info(`Tag duplicated!`);
                                        }}
                                    />
                                    <DeleteIcon
                                        title="Delete this?"
                                        disabled={disableWrite}
                                        onConfirm={() => {
                                            let tmpBlock = cloneDeep(items);
                                            pullAt(tmpBlock, data.key);
                                            setBlock(tmpBlock);
                                            message.success(
                                                "successfully removed",
                                            );
                                        }}
                                    />
                                </div>
                            ),
                        },
                    ]}
                    rowKey="index"
                    bordered
                    rowClassName="item-rows"
                    footer={() =>
                        disableWrite ? (
                            <></>
                        ) : (
                            <>
                                {componentsSchema.map(
                                    (item: any, key: number) => (
                                        <Button
                                            key={key}
                                            ghost
                                            type="primary"
                                            onClick={() => {
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock.push({
                                                    ...item,
                                                    genericId: uuid(),
                                                });
                                                setBlock(tmpBlock);
                                            }}
                                        >
                                            Add {splitAndUpperCase(item.type)}
                                        </Button>
                                    ),
                                )}
                                {!items?.length && (
                                    <span
                                        style={{
                                            color: "red",
                                            marginLeft: "10px",
                                            fontSize: "16px",
                                        }}
                                    >
                                        No Tags Added
                                    </span>
                                )}
                            </>
                        )
                    }
                    components={{
                        body: {
                            row: DraggableRow,
                        },
                    }}
                    onRow={(_, index) => ({
                        index,
                        moveRow,
                        disableWrite,
                    })}
                />
            </DndProvider>
            {!disableWrite && (
                <>
                    <Divider />
                    {items?.length > 0 && (
                        <>
                            <Button
                                disabled={disableWrite}
                                type="primary"
                                onClick={() => {
                                    message.info(
                                        `Copied Tags config to clipboard!`,
                                    );

                                    navigator.clipboard.writeText(
                                        JSON.stringify(items),
                                    );
                                }}
                            >
                                Copy this components Config to Clipboard
                            </Button>
                            <Divider />
                        </>
                    )}
                    <Form.Item label={"Paste tags config here"}>
                        <Input.TextArea
                            disabled={disableWrite}
                            value={tempConfig}
                            placeholder="Paste Config here"
                            onChange={(e) => setTempConfig(e.target.value)}
                        />
                        <Button
                            disabled={disableWrite}
                            style={{ marginTop: "10px" }}
                            type="primary"
                            onClick={() => {
                                try {
                                    const parsedJson = JSON.parse(tempConfig);
                                    if (!Array.isArray(parsedJson)) {
                                        message.error("Error in JSON!");
                                        return;
                                    }
                                    setBlock(parsedJson);
                                    setTempConfig("");
                                    message.info("Updated!");
                                } catch (e) {
                                    // captureException(e)
                                    message.error("Error in JSON!");
                                }
                            }}
                        >
                            Update config
                        </Button>
                    </Form.Item>
                </>
            )}
        </div>
    );
};

const DraggableRow = ({ index, moveRow, disableWrite, ...restProps }) => {
    const ref = useRef();
    const [, drop] = useDrop({
        accept: "row",
        drop(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            // Position checking logic (as in previous example) can be added here for better UX
            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [, drag] = useDrag({
        type: "row",
        item: { index },
    });

    drag(drop(ref));
    return <tr ref={disableWrite ? null : ref} {...restProps} />;
};

export default TagsTable;
