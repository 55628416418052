import React, { useMemo } from "react";
import TableRow, { TableRowLabels } from "./TableRow";
import { generateBorderConfig } from "./helpers";
import DrawingComponent from "./DrawingComponent";

function getAlignmentProperty(align: string) {
    switch (align) {
        case "LEFT":
            return "self-start";
        case "RIGHT":
            return "self-end";
        case "CENTER":
            return "center";
        default:
            return "self-start";
    }
}

const TablePreviewComponent = (props: any) => {
    const { tableData, showMeasures } = props;

    if (!tableData) {
        return null;
    }

    const customizations = {
        border: tableData?.border?.enabled && tableData?.border,
        gap: tableData?.gap?.enabled && tableData?.gap,
        reorder:
            tableData?.reorder?.type &&
            tableData?.reorder?.type !== "NONE" &&
            tableData?.reorder,
        swipable: tableData?.swipable?.enabled && tableData?.swipable,
        minMaxWidth: tableData?.minMaxWidth,
        minHeightCustomization: tableData?.minHeight,
        align: tableData?.align,
        padding: tableData?.padding?.enabled && tableData?.padding,
    };

    const tableChildProps = {
        borderCustomizations: customizations.border,
        minMaxWidth: customizations.minMaxWidth,
        minHeightCustomization: customizations.minHeightCustomization,
        paddingCustomization: customizations.padding,
        tableData: tableData,
    };

    const tableFeedbackBorder = tableData.highlightBorder
        ? generateBorderConfig(tableData.highlightBorder)
        : {};

    const tableAlignmentStyle = useMemo(() => {
        const alignmentProperty = getAlignmentProperty(customizations?.align);
        return {
            display: "grid",
            placeItems: alignmentProperty,
        };
    }, [customizations.align]);

    const drawingEnabled = tableData?.drawing?.enabled;

    return (
        <div
            style={{
                maxHeight: "85vh",
                maxWidth: "100%",
                overflow: "auto",
                ...tableFeedbackBorder,
                ...tableAlignmentStyle,
                position: "relative",
                padding: "10px",
                paddingLeft: "80px",
            }}
        >
            {showMeasures && <TableRowLabels tableData={tableData} />}
            {drawingEnabled ? (
                <DrawingComponent tableChildProps={tableChildProps} />
            ) : (
                <table
                    className="renderer-table"
                    id="table-preview-renderer"
                    style={{
                        borderSpacing: customizations.gap
                            ? `${customizations.gap.horizontal || 0}px ${
                                  customizations.gap.vertical || 0
                              }px`
                            : "0px",
                    }}
                >
                    <tbody id="table-preview-renderer-body">
                        {tableData.cells?.map((row: any, rowIndex: number) => {
                            return (
                                <TableRow
                                    rowProps={tableChildProps}
                                    rowIndex={rowIndex}
                                    row={row}
                                    key={rowIndex}
                                />
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default TablePreviewComponent;
