import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Tooltip } from "antd";
import _, { set } from "lodash";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import emitter from "src/helpers/emitter";
import {
    REMOVE_ALL_HIGHLIGHTS,
    TOGGLE_HIGHLIGHT_PATH,
    TOGGLE_SELECTION,
} from "src/helpers/events/table/constants";
import TagForm from "./TagForm";

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    MouseSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

const SelectionTagForm = ({
    path,
    setPath,
    disableWrite,
    pathIndex,
    activePathIndex,
    setActivePathIndex,
}: any) => {
    const [activeTags, setActiveTags] = useState<any>([]);
    const [stringifiedCells, setStringifiedCells] = useState<string[]>(
        path.cells?.map((cell: any) => cell.join("-")),
    );
    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 5,
        },
    });
    const sensors = useSensors(
        // useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        mouseSensor,
    );
    const isActive = useMemo(() => {
        return activePathIndex === pathIndex;
    }, [activePathIndex, pathIndex]);

    const handleChangeIsViewingCells = async (
        hovering: boolean,
        override: boolean = false,
        pathData = path,
    ) => {
        if (isActive && !override) {
            setActivePathIndex(null);
            emitter.emit(REMOVE_ALL_HIGHLIGHTS);
        } else {
            await new Promise((resolve) => {
                emitter.emit(REMOVE_ALL_HIGHLIGHTS, {
                    resolve,
                });
            });
            emitter.emit(TOGGLE_HIGHLIGHT_PATH, {
                cells: pathData.cells,
                isHighlighted: hovering,
            });
            setActivePathIndex(pathIndex);
        }
    };

    // event listener for toggle selection
    useEffect(() => {
        const handleToggleSelection = (data: any) => {
            if (!isActive) return;
            let tmpPath = _.cloneDeep(path);
            if (data.isAdded) {
                tmpPath.cells.push([
                    data.coordinates.rowIndex,
                    data.coordinates.columnIndex,
                ]);
            } else {
                tmpPath.cells = tmpPath.cells.filter((cell: any) => {
                    return !_.isEqual(cell, [
                        data.coordinates.rowIndex,
                        data.coordinates.columnIndex,
                    ]);
                });
            }
            setStringifiedCells(
                tmpPath.cells.map((cell: any) => cell.join("-")),
            );
            setPath(tmpPath);
        };
        emitter.on(TOGGLE_SELECTION, handleToggleSelection);
        return () => {
            emitter.off(TOGGLE_SELECTION, handleToggleSelection);
        };
    }, [pathIndex, path, isActive]);

    const handleDragEnd = (event: any) => {
        if (!event.active) return;
        const { active, over } = event;
        const activeIndex = stringifiedCells.indexOf(active.id);
        const overIndex = stringifiedCells.indexOf(over.id);
        if (activeIndex === -1 || overIndex === -1) return;
        const newCells = arrayMove(stringifiedCells, activeIndex, overIndex); // setPath({
        //     ...path,
        //     cells: newCells,
        // });
        setStringifiedCells(newCells);

        setPath({
            ...path,
            cells: newCells.map((cell: string) =>
                cell.split("-").map((c: string) => parseInt(c)),
            ),
        });
    };
    return (
        <>
            <Card
                style={{
                    border: `2px solid ${isActive ? "blue" : "#f0f0f0"}`,
                    cursor: "pointer",
                    minHeight: "52px",
                }}
                className="table-path-card"
                bodyStyle={{
                    padding: "10px",
                    backgroundColor: "white",
                    // display: "grid",
                    // gridTemplateRows: "25px",
                    // gridAutoRows: "25px",
                    // gridTemplateColumns: "repeat(auto-fill, minmax(60px, 1fr))",
                    // gap: "5px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    pointerEvents: "none",
                    position: "relative",
                    paddingRight: "40px",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        pointerEvents: "auto",
                    }}
                >
                    <Tooltip title={isActive ? "Disable view" : "View Cells"}>
                        <Button
                            onClick={() => {
                                handleChangeIsViewingCells(!isActive);
                            }}
                            color={isActive ? "default" : "primary"}
                            shape="circle"
                        >
                            {isActive ? (
                                <EyeInvisibleOutlined />
                            ) : (
                                <EyeOutlined />
                            )}
                        </Button>
                    </Tooltip>
                </div>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={stringifiedCells}
                        disabled={disableWrite || !isActive}
                        // strategy={verticalListSortingStrategy}
                    >
                        {stringifiedCells?.map((cell: any, index: number) => {
                            return (
                                <TagForm
                                    key={cell}
                                    cell={cell}
                                    isActive={isActive}
                                    tagIndex={cell}
                                    removeTag={async () => {
                                        let tmpPath = _.cloneDeep(path);
                                        tmpPath.cells = tmpPath.cells.filter(
                                            (c: any, ind: number) =>
                                                ind !== index,
                                        );
                                        await handleChangeIsViewingCells(
                                            true,
                                            true,
                                            tmpPath,
                                        );
                                        setStringifiedCells(
                                            tmpPath.cells.map((c: any) =>
                                                c.join("-"),
                                            ),
                                        );
                                        setPath(tmpPath);
                                    }}
                                    activeTags={activeTags}
                                    setActiveTags={setActiveTags}
                                />
                            );
                        })}
                    </SortableContext>
                </DndContext>
            </Card>
        </>
    );
};

export default SelectionTagForm;
