import { compose, getLastStringFromUrl } from "src/helpers";
import { checkBlockValid } from "src/modules/worksheet/components/blocks";
import {
    withDeleteBlocks,
    withDeleteWorksheetBlockMap,
    withHookForWorksheet,
    withWorksheetStats,
    withHookForWorksheetStories,
    withInsertWorksheetBlockMap,
    withUpdateWorksheet,
    withUpdateWorksheetUserStats,
    withVersionModelMap,
    withWorksheetBlocks,
    withHookForWorksheetAudios,
    withUpdateWorksheetBlock,
    withInsertWorksheetBlock,
    withWorksheetPublishedBlocks,
} from "src/modules/worksheet/operations";
import { message, Modal, Typography } from "antd";
import _ from "lodash";

import { useRouter } from "src/helpers";
import { useEffect, useState } from "react";
import CreateView from "../../components/WorksheetEditor";
import { useAuth0 } from "@auth0/auth0-react";
import {
    generateAudios,
    addGenerateLogicForVarAudio,
} from "../../components/WorksheetEditor/helpers/generateAudios";
import axios from "axios";
import { captureException } from "@sentry/react";
import { publishToSecondaryHasuraEndpoints } from "../../helpers/publishToSecondaryHasuraEndpoints";

const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getV4WorksheetBlockMapObject = ({
    blockData,
    worksheetData,
}: any) => {
    return blockData.map((block: any, idx: number) => {
        const { worksheet_block_map_id } = block;
        const children = block?.children?.length
            ? {
                  children: {
                      data: block.children?.map((child: any, index: number) => {
                          const gChildren = child?.children?.length
                              ? {
                                    children: {
                                        data: child.children?.map(
                                            (child: any, index: number) => {
                                                return {
                                                    ..._.omit(child, [
                                                        "tmpId",
                                                        "__typename",
                                                        "tags",
                                                        "children",
                                                    ]),
                                                    order: index + 1,
                                                };
                                            },
                                        ),
                                        on_conflict: {
                                            constraint: "block_pkey",
                                            update_columns: [
                                                "data",
                                                "type",
                                                "order",
                                                "backend",
                                            ],
                                        },
                                    },
                                }
                              : {};

                          return {
                              ...gChildren,
                              ..._.omit(child, [
                                  "tmpId",
                                  "__typename",
                                  "tags",
                                  "children",
                              ]),
                              order: index + 1,
                          };
                      }),
                      on_conflict: {
                          constraint: "block_pkey",
                          update_columns: ["data", "type", "order", "backend"],
                      },
                  },
              }
            : {};

        return {
            ...(worksheet_block_map_id ? { id: worksheet_block_map_id } : {}),
            order: idx + 1,
            ...worksheetData,
            block: {
                data: {
                    ...children,
                    ..._.omit(block, [
                        "tmpId",
                        "worksheet_block_map_id",
                        "__typename",
                        "children",
                        "tags",
                    ]),
                },
                on_conflict: {
                    constraint: "block_pkey",
                    update_columns: ["data", "type", "backend"],
                },
            },
        };
    });
};

export const getWorksheetBlockIdMap = ({ blockData, worksheetData }: any) => {
    return blockData.map((block: any, idx: number) => {
        const { worksheet_block_map_id, levelId } = block;
        return {
            ...(worksheet_block_map_id ? { id: worksheet_block_map_id } : {}),
            order: idx + 1,
            ...worksheetData,
            ...(levelId
                ? { block_id: levelId }
                : {
                      block: {
                          data: block,
                          on_conflict: {
                              constraint: "block_pkey",
                              update_columns: ["data", "type", "backend"],
                          },
                      },
                  }),
        };
    });
};

export const getV4ParsedBlocks = (blocksArray: any) => {
    return blocksArray
        ?.slice()
        .sort(function (a: any, b: any) {
            return a.order - b.order;
        })
        .map(({ id: worksheet_block_map_id, block: level }: any) => ({
            ...level,
            children: level.children
                ?.slice()
                ?.sort(function (a: any, b: any) {
                    return a.order - b.order;
                })
                ?.map((chunk: any) => ({
                    ...chunk,
                    children: chunk.children
                        ?.slice()
                        ?.sort(function (a: any, b: any) {
                            return a.order - b.order;
                        }),
                })),
            worksheet_block_map_id,
        }));
};

function createReferenceIdMap(blocks: any[]) {
    const referenceIdMap: any = {};

    function traverseBlock(block: any) {
        if (block.reference_id !== null) {
            referenceIdMap[block.reference_id] = block.id;
        }
        if (block.children) {
            block.children.forEach(traverseBlock);
        }
    }

    blocks.forEach((block) => traverseBlock(block?.block));
    return referenceIdMap;
}

const WorksheetEdit = (props) => {
    const {
        updateWorksheet,
        worksheetBlockMap,
        refetchWorksheetBlockMap,
        loadingWorksheetMap,
        insertWorksheetBlockMap,
        deleteWorksheetBlockMap,
        deleteBlocks,
        worksheet,
        versionModelMap,
        insertWorksheetBlock,
        updateWorksheetBlock,
        updateWorksheetUserStats,

        storyJobs,
        storyJobsLoading,
        audioJobs,
        audioJobsLoading,

        refetchWorksheetStats,
        worksheetStatsLoading,
        worksheetStats,
        loadingPublishedBlocks,
        publlishedBlocks,
        refetchPublishedBlocks,
    } = props;
    const router = useRouter();

    const { user } = useAuth0();

    let worksheet_id = worksheet?.id;
    const { redirect_to } = router.query;

    const [isSaving, toggleSaving] = useState(false);
    const [shouldBlockUI, toggleShouldBlockUI] = useState(false);

    const [currentBlock, setCurrentBlock] = useState<any>(null);
    const [currentSubBlock, setCurrentSubBlock] = useState<any>(null);
    const [currentChunkBlock, setCurrentChunkBlock] = useState<any>(null);

    const [hasChanged, toggleHasChanged] = useState(false);
    const [lastSavedAt, setLastSavedAt] = useState<any>(null);
    const [blocks, setBlocks] = useState<any>([]);

    const [isOpen, setIsOpen] = useState(false);
    const [saveVal, setSaveVal] = useState(false);
    const [worksheetStatsState, setWorksheetStatsState] = useState<any>({});
    const [currentSavedCount, setCurrentSavedCount] = useState<any>(0);
    const [publishedBlocksMap, setPublishedBlocksMap] = useState<any>({});

    useEffect(() => {
        if (worksheet && worksheet?.type !== "personalized_learning_v4") {
            let query = router.query;
            delete query.worksheet_id;
            router.push({
                pathname: `/worksheet/update_enhanced/${worksheet?.id}`,
                query,
            });
        }
    }, [worksheet, router]);

    useEffect(() => {
        if (
            !worksheetStatsLoading &&
            worksheetStats &&
            !worksheetStatsState?.other
        ) {
            setWorksheetStatsState(worksheetStats);
            setCurrentSavedCount(worksheetStats?.other?.save_count);
        }
    }, [worksheetStatsLoading, worksheetStats]);

    useEffect(() => {
        if (!loadingPublishedBlocks) {
            setPublishedBlocksMap(createReferenceIdMap(publlishedBlocks));
        }
    }, [loadingPublishedBlocks, publlishedBlocks]);

    useEffect(() => {
        setParsedBlocks(worksheetBlockMap);
    }, [worksheetBlockMap]);

    useEffect(() => {
        // Every 10s, run a mutation to tell the backend that you're online
        let onlineIndicator: string | number | NodeJS.Timeout | undefined;
        let v = setTimeout(() => {
            updateLastSeen();
            onlineIndicator = setInterval(() => updateLastSeen(), 10000);
        }, 2000);

        return () => {
            // Clean up
            onlineIndicator && clearInterval(onlineIndicator);
            clearTimeout(v);
        };
    }, []);

    useEffect(() => {
        if (saveVal) {
            onSave({});
            setSaveVal(false);
        }
    }, [saveVal]);

    useEffect(() => {
        if (!storyJobsLoading && storyJobs?.length) {
            message.info("Story fetched. Check them out!");
        }
    }, [storyJobs, storyJobsLoading]);

    useEffect(() => {
        if (!audioJobsLoading && audioJobs?.length) {
            message.info("Audio fetched. Check them out!");
        }
    }, [audioJobs, audioJobsLoading]);

    const updateLastSeen = async () => {
        if (
            user &&
            document?.visibilityState === "visible" &&
            navigator?.onLine
        ) {
            const {
                email,
                "https://hasura.io/jwt/claims/user_id": user_id,
                name,
            } = user;

            try {
                const {
                    data: { worksheet_worksheet_stats },
                } = await refetchWorksheetStats();
                let insertObject = _.cloneDeep(
                    worksheet_worksheet_stats?.[0]?.other || {},
                );

                if (insertObject?.active_users) {
                    const { active_users } = insertObject;

                    let isEditorActive = true;
                    let isUserPresent = false;

                    active_users.forEach(
                        (element: {
                            user_id: any;
                            is_editor: boolean;
                            last_seen: string | number | Date;
                        }) => {
                            if (user_id === element.user_id)
                                isUserPresent = true;
                            if (element.is_editor) {
                                const activeTime = new Date(
                                    element.last_seen,
                                ).getTime();
                                const currentTime = new Date().getTime();
                                const timeDifferenceSecs = Math.floor(
                                    (currentTime - activeTime) / 1000,
                                );

                                if (timeDifferenceSecs > 60) {
                                    isEditorActive = false;
                                }
                            }
                        },
                    );

                    let tempActiveUsers = _.cloneDeep(active_users);

                    if (!isUserPresent) {
                        tempActiveUsers.push({
                            user_id,
                            email,
                            name,
                            last_seen: new Date().toISOString(),
                            is_editor: false,
                        });
                    } else {
                        tempActiveUsers = tempActiveUsers.map(
                            (item: { user_id: any; last_seen: any }) => {
                                return {
                                    ...item,
                                    last_seen:
                                        user_id === item.user_id
                                            ? new Date().toISOString()
                                            : item.last_seen,
                                };
                            },
                        );
                    }

                    tempActiveUsers = tempActiveUsers.sort(
                        (
                            a: {
                                last_seen: string | number | Date;
                                is_editor: boolean;
                            },
                            b: {
                                last_seen: string | number | Date;
                                is_editor: boolean;
                            },
                        ) => {
                            if (a.is_editor && !b.is_editor) {
                                return -1; // a is an editor, b is not an editor (a comes first)
                            } else if (!a.is_editor && b.is_editor) {
                                return 1; // a is not an editor, b is an editor (b comes first)
                            } else {
                                const timeA = new Date(a.last_seen).getTime();
                                const timeB = new Date(b.last_seen).getTime();
                                return timeB - timeA; // Sort based on last_seen in descending order (latest on top)
                            }
                        },
                    );

                    if (!isEditorActive) {
                        tempActiveUsers = tempActiveUsers.map(
                            (item: { user_id: any }) => {
                                return {
                                    ...item,
                                    is_editor: user_id === item.user_id,
                                };
                            },
                        );
                    }

                    insertObject.active_users = tempActiveUsers;
                } else {
                    insertObject = {
                        ...insertObject,
                        active_users: [
                            {
                                user_id,
                                email,
                                name,
                                last_seen: new Date().toISOString(),
                                is_editor: true,
                            },
                        ],
                    };
                }

                let worksheetId = worksheet_id || getLastStringFromUrl();
                if (worksheetId) {
                    const response = await updateWorksheetUserStats({
                        other: insertObject,
                        worksheet_id: worksheetId,
                    });
                    setWorksheetStatsState(response);
                }
            } catch (e) {
                captureException(e);
                console.log(e);
            }
        }
    };

    const setActiveEditor = async () => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return;
        }
        try {
            if (user) {
                const {
                    email,
                    "https://hasura.io/jwt/claims/user_id": user_id,
                    name,
                } = user;

                const {
                    data: { worksheet_worksheet_stats },
                } = await refetchWorksheetStats();
                let insertObject = _.cloneDeep(
                    worksheet_worksheet_stats?.[0]?.other || {},
                );

                if (insertObject?.active_users) {
                    const { active_users } = insertObject;

                    let isUserPresent = active_users.some(
                        (element: {
                            user_id: any;
                            is_editor: boolean;
                            last_seen: string | number | Date;
                        }) => {
                            return user_id === element.user_id;
                        },
                    );

                    let tempActiveUsers = _.cloneDeep(active_users);

                    if (!isUserPresent) {
                        tempActiveUsers.push({
                            user_id,
                            email,
                            name,
                            last_seen: new Date().toISOString(),
                            is_editor: true,
                        });
                    } else {
                        tempActiveUsers = tempActiveUsers.map(
                            (item: { user_id: any; last_seen: any }) => {
                                return {
                                    ...item,
                                    is_editor: user_id === item.user_id,
                                };
                            },
                        );
                    }

                    insertObject.active_users = tempActiveUsers;
                } else {
                    insertObject = {
                        ...insertObject,
                        active_users: [
                            {
                                user_id,
                                email,
                                name,
                                last_seen: new Date().toISOString(),
                                is_editor: true,
                            },
                        ],
                    };
                }

                const response = await updateWorksheetUserStats({
                    other: insertObject,
                    worksheet_id,
                });
                setWorksheetStatsState(response);
            }
        } catch (e) {
            captureException(e);
            console.log(e);
        }
    };

    if (window && history) {
        window.onpopstate = function () {
            setIsOpen(true);
        };
        history.pushState({}, "");
    }

    const handleClose = async () => setIsOpen(false);

    const validateBlocks = () => {
        const arr = blocks.map((blockObj: any) => {
            const tmpArr = [];

            tmpArr.push(checkBlockValid(blockObj, worksheet));

            if (blockObj.children && blockObj.children.length) {
                blockObj.children.map((child: any) => {
                    tmpArr.push(checkBlockValid(child, worksheet));
                });
            }
            return tmpArr;
        });
        return arr.flat();
    };

    const onFinish = async (values: any) => {
        await updateWorksheet({
            object: _.omit(values, ["tags"]),
            id: values.id,
        });
    };

    const setParsedBlocks = (blocksArray: any) => {
        if (blocksArray?.length) {
            const parsedBlocks = getV4ParsedBlocks(blocksArray);
            setBlocks(parsedBlocks || []);
            if (!currentBlock) {
                setCurrentBlock(0);
            }
            return parsedBlocks;
        }
        return [];
    };

    const saveBlocks = async ({
        currentBlocks,
        publish,
        levelReset,
        shouldPublishToSecondaryHasuraEndpoints = false,
    }: any) => {
        let retVal = true;
        let blockData = currentBlocks || blocks;

        let resBlocks = await Promise.all(
            blockData?.map(async (level) => {
                if (!level?.id) {
                    const children = level?.children?.length
                        ? {
                              children: {
                                  data: level.children?.map(
                                      (child: any, index: number) => {
                                          const gChildren = child?.children
                                              ?.length
                                              ? {
                                                    children: {
                                                        data: child.children?.map(
                                                            (
                                                                grandchild: any,
                                                                gIndex: number,
                                                            ) => {
                                                                return {
                                                                    ..._.omit(
                                                                        grandchild,
                                                                        [
                                                                            "tmpId",
                                                                            "__typename",
                                                                            "tags",
                                                                            "children",
                                                                        ],
                                                                    ),
                                                                    order:
                                                                        gIndex +
                                                                        1,
                                                                };
                                                            },
                                                        ),
                                                        on_conflict: {
                                                            constraint:
                                                                "block_pkey",
                                                            update_columns: [
                                                                "data",
                                                                "type",
                                                                "order",
                                                                "backend",
                                                            ],
                                                        },
                                                    },
                                                }
                                              : {};

                                          return {
                                              ...gChildren,
                                              ..._.omit(child, [
                                                  "tmpId",
                                                  "__typename",
                                                  "tags",
                                                  "children",
                                              ]),
                                              order: index + 1,
                                          };
                                      },
                                  ),
                                  on_conflict: {
                                      constraint: "block_pkey",
                                      update_columns: [
                                          "data",
                                          "type",
                                          "order",
                                          "backend",
                                      ],
                                  },
                              },
                          }
                        : {};
                    const varData = {
                        ...children,
                        ..._.omit(level, [
                            "tmpId",
                            "worksheet_block_map_id",
                            "__typename",
                            "children",
                            "tags",
                        ]),
                    };
                    return varData;
                } else {
                    const savedLevelMap = worksheetBlockMap?.find(
                        (v) => v.block?.id === level?.id,
                    );
                    const savedLevel = savedLevelMap?.block;
                    if (
                        !_.isEqual(
                            _.omit(level, [
                                "id",
                                "tmpId",
                                "__typename",
                                "children",
                                "tags",
                                "worksheet_block_map_id",
                            ]),
                            _.omit(savedLevel, [
                                "id",
                                "tmpId",
                                "__typename",
                                "children",
                                "tags",
                                "worksheet_block_map_id",
                            ]),
                        )
                    ) {
                        await updateWorksheetBlock({
                            object: _.omit(level, [
                                "tmpId",
                                "worksheet_block_map_id",
                                "__typename",
                                "children",
                                "tags",
                            ]),
                            id: level.id,
                        });
                    }
                    await Promise.all(
                        level?.children?.map(async (chunk, cIndex) => {
                            if (!chunk?.id) {
                                const children = chunk?.children?.length
                                    ? {
                                          children: {
                                              data: chunk.children?.map(
                                                  (
                                                      child: any,
                                                      index: number,
                                                  ) => {
                                                      return {
                                                          ..._.omit(child, [
                                                              "tmpId",
                                                              "__typename",
                                                              "tags",
                                                              "children",
                                                          ]),
                                                          order: index + 1,
                                                      };
                                                  },
                                              ),
                                              on_conflict: {
                                                  constraint: "block_pkey",
                                                  update_columns: [
                                                      "data",
                                                      "type",
                                                      "order",
                                                      "backend",
                                                  ],
                                              },
                                          },
                                      }
                                    : {};
                                const varData = {
                                    ...children,
                                    ..._.omit(chunk, [
                                        "tmpId",
                                        "worksheet_block_map_id",
                                        "__typename",
                                        "children",
                                        "tags",
                                    ]),
                                    order: cIndex + 1,
                                    parent_id: level.id,
                                };

                                const data = await insertWorksheetBlock(
                                    varData,
                                );
                                return data?.id;
                            } else {
                                const savedChunk = savedLevel?.children?.find(
                                    (v) => v?.id === chunk?.id,
                                );

                                if (
                                    !_.isEqual(
                                        _.omit(
                                            { ...chunk, order: cIndex + 1 },
                                            [
                                                "id",
                                                "tmpId",
                                                "__typename",
                                                "children",
                                                "tags",
                                            ],
                                        ),
                                        _.omit(savedChunk, [
                                            "id",
                                            "tmpId",
                                            "__typename",
                                            "children",
                                            "tags",
                                        ]),
                                    )
                                ) {
                                    await updateWorksheetBlock({
                                        object: _.omit(
                                            { ...chunk, order: cIndex + 1 },
                                            [
                                                "tmpId",
                                                "worksheet_block_map_id",
                                                "__typename",
                                                "children",
                                                "tags",
                                            ],
                                        ),
                                        id: chunk.id,
                                    });
                                }
                                await Promise.all(
                                    chunk?.children?.map(
                                        async (block, bIndex) => {
                                            if (!block?.id) {
                                                const varData = {
                                                    ..._.omit(block, [
                                                        "tmpId",
                                                        "worksheet_block_map_id",
                                                        "__typename",
                                                        "children",
                                                        "tags",
                                                    ]),
                                                    parent_id: chunk.id,
                                                    order: bIndex + 1,
                                                };
                                                const data =
                                                    await insertWorksheetBlock(
                                                        varData,
                                                    );
                                                return data?.id;
                                            } else {
                                                const savedBlock =
                                                    savedChunk?.children?.find(
                                                        (v) =>
                                                            v?.id === block?.id,
                                                    );

                                                if (
                                                    !_.isEqual(
                                                        _.omit(
                                                            {
                                                                ...block,
                                                                order:
                                                                    bIndex + 1,
                                                            },
                                                            [
                                                                "id",
                                                                "tmpId",
                                                                "__typename",
                                                                "children",
                                                                "tags",
                                                            ],
                                                        ),
                                                        _.omit(savedBlock, [
                                                            "id",
                                                            "tmpId",
                                                            "__typename",
                                                            "children",
                                                            "tags",
                                                        ]),
                                                    )
                                                ) {
                                                    await updateWorksheetBlock({
                                                        object: {
                                                            ..._.omit(block, [
                                                                "tmpId",
                                                                "worksheet_block_map_id",
                                                                "__typename",
                                                                "children",
                                                                "tags",
                                                            ]),
                                                            parent_id: chunk.id,
                                                            order: bIndex + 1,
                                                        },
                                                        id: block.id,
                                                    });
                                                }
                                                return block?.id;
                                            }
                                        },
                                    ),
                                );
                                return chunk?.id;
                            }
                        }),
                    );
                    return {
                        levelId: level?.id,
                        worksheet_block_map_id: savedLevelMap?.id,
                    };
                }
            }),
        );

        const objects: any = getV4WorksheetBlockMapObject({
            blockData,
            worksheetData: { worksheet_id },
        });

        const updateObjects: any = getWorksheetBlockIdMap({
            blockData: resBlocks,
            worksheetData: { worksheet_id },
        });

        const existingWorksheetBlockMapIds = objects
            .map(({ id }: any) => id)
            .filter((n: any) => n);
        const allWorksheetBlockMapIds = worksheetBlockMap.map(
            ({ id }: any) => id,
        );
        const missingWorksheetBlockMapIds = allWorksheetBlockMapIds.filter(
            (x: any) => !existingWorksheetBlockMapIds.includes(x),
        );

        const allChildrenBlockIds = worksheetBlockMap
            .flatMap(({ block: { children, id } }: any) => [
                id,
                ...children.flatMap(({ id, children: gChildren }: any) => {
                    const gChildrenIds = (gChildren || []).map(
                        ({ id }: any) => id,
                    );
                    return [id, ...gChildrenIds];
                }),
            ])
            .filter((n: any) => n);

        const existingChildrenBlockIds = blockData
            ?.flatMap(({ children = [], id }: any) => [
                id,
                ...children?.flatMap(
                    ({ id, children: gChildren = [] }: any) => [
                        id,
                        ...gChildren?.map(({ id }: any) => id),
                    ],
                ),
            ])
            .filter((n: any) => n);

        const missingChildrenBlockIds = allChildrenBlockIds.filter(
            (x: any) => !existingChildrenBlockIds.includes(x),
        );

        if (missingChildrenBlockIds.length)
            await deleteBlocks({ ids: missingChildrenBlockIds });

        if (missingWorksheetBlockMapIds.length)
            await deleteWorksheetBlockMap({
                ids: missingWorksheetBlockMapIds,
            });

        await insertWorksheetBlockMap({
            objects: updateObjects,
        });

        const {
            data: { worksheetBlockMap: newWorksheetBlockMap },
        } = await refetchWorksheetBlockMap();

        blockData = setParsedBlocks(newWorksheetBlockMap);

        // START: handle versioning
        const {
            data: { worksheet_worksheet_stats },
        } = await refetchWorksheetStats();
        let insertObject = _.cloneDeep(
            worksheet_worksheet_stats?.[0]?.other || {},
        );

        const { save_count } = insertObject;

        const {
            email,
            "https://hasura.io/jwt/claims/user_id": user_id,
            name,
        } = user || {};

        let currentSaved = {
            time: new Date(),
            user: {
                email,
                user_id,
                name,
            },
        };
        insertObject.save_count = (save_count || 0) + 1;
        insertObject.current_save = currentSaved;
        insertObject.block_count = blockData?.length;
        if (publish) {
            try {
                // const res =
                await axios
                    .post(
                        `${process.env.REACT_APP_HOMEWORK_SERVER_API_ENDPOINT}/v3/personalizedLearning/publishWorksheetV4`,
                        {
                            data: {
                                worksheet_id: Number(worksheet_id),
                                level_reset: levelReset || [],
                            },
                        },
                        { headers: { "Content-Type": "application/json" } },
                    )
                    .then(function (response) {
                        return response?.data;
                    });

                // console.log("RESPONSE FROM API", res);
                insertObject.publish_count = (save_count || 0) + 1;
                insertObject.current_publish = currentSaved;

                refetchPublishedBlocks();
                await axios.post(
                    `${process.env.REACT_APP_HOMEWORK_SERVER_API_ENDPOINT}/v3/personalizedLearning/deleteWorksheetMetadataCache`,
                    { data: { id: worksheet_id } },
                );
            } catch (e) {
                captureException(e);
                retVal = false;
                console.log("Publish Failed", e);
            }
        }

        const response = await updateWorksheetUserStats({
            other: insertObject,
            worksheet_id,
        });

        setCurrentSavedCount(response?.other?.save_count);
        setWorksheetStatsState(response);
        // END: handle versioning

        // publish to other endpoints
        if (shouldPublishToSecondaryHasuraEndpoints)
            await publishToSecondaryHasuraEndpoints(
                worksheet,
                newWorksheetBlockMap,
            );

        return retVal;
    };

    const onSave = async ({
        showMessage = true,
        publish = false,
        generate = true,
        levelReset,
        shouldPublishToSecondaryHasuraEndpoints,
    }: any) => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return false;
        }
        let retVal = true;
        if (!isSaving && blocks.length) {
            toggleSaving(true);
            try {
                if (showMessage) toggleShouldBlockUI(true);
                // NEEDS UPDATE
                const newCurrentBlocks = await addGenerateLogicForVarAudio(
                    blocks,
                    worksheet?.type,
                );
                if (publish || generate) {
                    // NEEDS UPDATE
                    const { job_id, error } = await generateAudios(
                        blocks,
                        worksheet?.type,
                    );
                    if (job_id) {
                        setNewAudioJob({ job_id });
                    }
                    if (error) {
                        message.error(
                            `Failed to generate audio for worksheet!`,
                        );
                        console.log("Error audio:", error);
                        // return false;
                    }
                }
                let [res] = await Promise.all([
                    saveBlocks({
                        currentBlocks: newCurrentBlocks,
                        publish,
                        worksheet_type: worksheet.type,
                        levelReset,
                        shouldPublishToSecondaryHasuraEndpoints,
                    }),
                    delay(1000),
                ]);

                if (!res) retVal = false;
                setLastSavedAt(new Date());
                if (showMessage) {
                    message.success(`Successfully updated worksheet!`);
                }
            } catch (e) {
                captureException(e);
                console.log("Error on save:", e);
                if (showMessage)
                    message.error(
                        `Something went wrong! try again in a couple of seconds`,
                    );
                retVal = false;
            }

            toggleHasChanged(false);
            toggleShouldBlockUI(false);
            toggleSaving(false);
        } else if (isSaving) {
            toggleHasChanged(false);
            toggleSaving(false);
            toggleShouldBlockUI(false);
        }
        return retVal;
    };

    const setNewJob = async ({ path, job_id, name }) => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return;
        }
        try {
            const {
                data: { worksheet_worksheet_stats },
            } = await refetchWorksheetStats();
            let insertObject = _.cloneDeep(
                worksheet_worksheet_stats?.[0]?.other || {},
            );
            if (insertObject?.story_jobs) {
                const { story_jobs } = insertObject;

                let tempJobs = _.cloneDeep(story_jobs);

                tempJobs.unshift({
                    name,
                    path,
                    job_id,
                    is_sync: false,
                });

                insertObject.story_jobs = tempJobs;
            } else {
                insertObject = {
                    ...insertObject,
                    story_jobs: [{ name, path, job_id, is_sync: false }],
                };
            }
            const response = await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
            setWorksheetStatsState(response);
        } catch (e) {
            captureException(e);
            console.log(e);
        }
    };
    const updateStoriesJob = async ({ job_id, is_delete }: any) => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return false;
        }
        try {
            const {
                data: { worksheet_worksheet_stats },
            } = await refetchWorksheetStats();
            let insertObject = _.cloneDeep(
                worksheet_worksheet_stats?.[0]?.other || {},
            );
            if (insertObject?.story_jobs) {
                const { story_jobs } = insertObject;

                let tempJobs = _.cloneDeep(story_jobs);

                tempJobs = is_delete
                    ? tempJobs.filter((v) => v.job_id != job_id)
                    : tempJobs.map((v) => {
                          if (v.job_id == job_id) {
                              v.is_sync = true;
                          }
                          return v;
                      });
                insertObject.story_jobs = tempJobs;
            }
            if (!is_delete) setSaveVal(true);
            const response = await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
            setWorksheetStatsState(response);
            return true;
        } catch (e) {
            captureException(e);
            console.log(e);
            return false;
        }
    };

    const setNewAudioJob = async ({ job_id }) => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return;
        }
        try {
            const {
                data: { worksheet_worksheet_stats },
            } = await refetchWorksheetStats();
            let insertObject = _.cloneDeep(
                worksheet_worksheet_stats?.[0]?.other || {},
            );
            if (insertObject?.audio_jobs) {
                const { audio_jobs } = insertObject;

                let tempJobs = _.cloneDeep(audio_jobs);

                tempJobs.unshift({
                    job_id,
                    is_sync: false,
                });

                insertObject.audio_jobs = tempJobs;
            } else {
                insertObject = {
                    ...insertObject,
                    audio_jobs: [
                        {
                            job_id,
                            is_sync: false,
                        },
                    ],
                };
            }
            const response = await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
            setWorksheetStatsState(response);
        } catch (e) {
            captureException(e);
            console.log(e);
        }
    };
    const updateAudiosJob = async ({ job_id, is_delete }: any) => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return false;
        }
        try {
            const {
                data: { worksheet_worksheet_stats },
            } = await refetchWorksheetStats();
            let insertObject = _.cloneDeep(
                worksheet_worksheet_stats?.[0]?.other || {},
            );
            if (insertObject?.audio_jobs) {
                const { audio_jobs } = insertObject;

                let tempJobs = _.cloneDeep(audio_jobs);

                tempJobs = is_delete
                    ? tempJobs.filter((v) => v.job_id != job_id)
                    : tempJobs.map((v) => {
                          if (v.job_id == job_id) {
                              v.is_sync = true;
                          }
                          return v;
                      });
                insertObject.audio_jobs = tempJobs;
            }
            const response = await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
            setWorksheetStatsState(response);
            if (!is_delete) setSaveVal(true);
            return true;
        } catch (e) {
            captureException(e);
            console.log(e);
            return false;
        }
    };

    // console.log(props);
    const version =
        versionModelMap &&
        versionModelMap[0]?.versions_aggregate?.aggregate?.max?.index + 1; // versionModelMap

    const createViewProps = {
        onFinish,
        onFinishFailed: () => {},
        redirect_to,
        isSaving,
        blocks,
        setBlocks: (blocksArray: any) => {
            setBlocks(blocksArray);
            toggleHasChanged(true);
        },
        onSave,
        currentBlock,
        setCurrentBlock: (blockIndex: number) => {
            setCurrentBlock(blockIndex);
            setCurrentSubBlock(null);
            setCurrentChunkBlock(null);
        },
        worksheet_id,
        currentSubBlock,
        setCurrentSubBlock,
        currentChunkBlock,
        setCurrentChunkBlock,
        lastSavedAt,
        currentDateTime: null,
        setSaveCounter: ({ currentBlocks }: any) => {
            onSave({ showMessage: true, currentBlocks });
        },
        shouldBlockUI,
        toggleShouldBlockUI,
        validateBlocks,
        version,
        hasChanged,
        toggleHasChanged,
        canPublish:
            worksheetStatsState?.other?.save_count !==
            worksheetStatsState?.other?.publish_count,
        onInputVariableUpdate: () => {},
        checkInputVarExist: () => {},
        activeUsers: worksheetStatsState?.other?.active_users,
        storyJobs,
        worksheetStoryJobs: worksheetStatsState?.other?.story_jobs || [],
        setActiveEditor,
        setNewJob,
        updateStoriesJob,
        audioJobs,
        worksheetAudioJobs: worksheetStatsState?.other?.audio_jobs || [],
        updateAudiosJob,
        setNewAudioJob,
        currentSavedCount,
        publishedBlocksMap,
    };

    if (loadingWorksheetMap) return <></>;
    return (
        <>
            <Modal
                open={isOpen}
                title="Are you sure you want to go back?"
                onOk={() => {
                    handleClose();
                    router.push(redirect_to);
                }}
                onCancel={handleClose}
            >
                <Typography.Text>
                    You'll lose any unsaved changes
                </Typography.Text>
            </Modal>
            <CreateView {...props} {...createViewProps} />
        </>
    );
};

export default compose(
    withHookForWorksheet,
    withUpdateWorksheet,
    withWorksheetBlocks,
    withWorksheetPublishedBlocks,
    withInsertWorksheetBlockMap,
    withDeleteWorksheetBlockMap,
    withDeleteBlocks,
    withVersionModelMap,
    withUpdateWorksheetUserStats,
    withWorksheetStats,
    withHookForWorksheetStories,
    withHookForWorksheetAudios,
    withUpdateWorksheetBlock,
    withInsertWorksheetBlock,
)(WorksheetEdit);
