import { DEFAULT_CELL } from "../../TableEditor";
import convertPathToClipPath from "./clipPathScript";

function parseSvg(svgString) {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
    const svgElement = svgDoc.documentElement;

    const shapes = [];
    const childElements = Array.from(svgElement.children);
    const shapeElements = childElements.filter(
        (element) => element.tagName !== "rect",
    );
    shapeElements.forEach((element) => {
        const shape = {};
        const position = {};
        const dimension = {};

        if (element.tagName === "path") {
            const d = element.getAttribute("d");

            // Parse the path data to extract coordinates
            const coords = [];
            const commands = d.match(/[A-Za-z][^A-Za-z]*/g);

            commands.forEach((command) => {
                const cmdType = command.charAt(0).toUpperCase();
                const args = command
                    .slice(1)
                    .trim()
                    .split(/[\s,]+/)
                    .map(Number);
                if (cmdType === "M" || cmdType === "L") {
                    for (let i = 0; i < args.length; i += 2) {
                        coords.push({ x: args[i], y: args[i + 1] });
                    }
                } else if (cmdType === "H") {
                    // Horizontal line: x coordinate changes, y remains the same
                    const x = args[0];
                    const lastY = coords[coords.length - 1].y;
                    coords.push({ x: x, y: lastY });
                } else if (cmdType === "V") {
                    // Vertical line: y coordinate changes, x remains the same
                    const y = args[0];
                    const lastX = coords[coords.length - 1].x;
                    coords.push({ x: lastX, y: y });
                }
                // Additional command types can be handled here
            });

            // Remove duplicate coordinates (e.g., due to closing the path)
            const uniqueCoords = [];
            coords.forEach((coord) => {
                if (
                    !uniqueCoords.some(
                        (c) => c.x === coord.x && c.y === coord.y,
                    )
                ) {
                    uniqueCoords.push(coord);
                }
            });

            const sides = uniqueCoords.length;

            // Assign shape type based on the number of unique coordinates (vertices)
            if (sides === 3) {
                shape.type = "Triangle";
            } else if (sides === 4) {
                shape.type = "Rectangle"; // Could also be a quadrilateral
            } else if (sides === 5) {
                shape.type = "Pentagon";
            } else if (sides === 6) {
                shape.type = "Hexagon";
            } else {
                shape.type = "Polygon"; // General polygon
            }

            shape.path = convertPathToClipPath(d);

            // Create an SVG element to calculate bounding box
            const tempSvg = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "svg",
            );
            const pathElement = element.cloneNode();
            tempSvg.appendChild(pathElement);
            document.body.appendChild(tempSvg);
            const bbox = pathElement.getBBox();
            document.body.removeChild(tempSvg);

            position.x = bbox.x;
            position.y = bbox.y;
            dimension.width = bbox.width;
            dimension.height = bbox.height;
        } else if (element.tagName === "circle") {
            const cx = parseFloat(element.getAttribute("cx"));
            const cy = parseFloat(element.getAttribute("cy"));
            const r = parseFloat(element.getAttribute("r"));

            position.x = cx - r;
            position.y = cy - r;
            position.r = r;
            dimension.width = r * 2;
            dimension.height = r * 2;

            shape.type = "Circle";
        } else if (element.tagName === "ellipse") {
            const cx = parseFloat(element.getAttribute("cx"));
            const cy = parseFloat(element.getAttribute("cy"));
            const rx = parseFloat(element.getAttribute("rx"));
            const ry = parseFloat(element.getAttribute("ry"));

            position.x = cx - rx;
            position.y = cy - ry;
            position.rx = rx;
            position.ry = ry;

            dimension.width = rx * 2;
            dimension.height = ry * 2;

            shape.type = "Ellipse";
        }

        shapes.push({
            drawing: {
                shape: shape,
                position: position,
                dimension: dimension, // Moved dimension inside drawing
            },
            // Other properties can be added here if needed
        });
    });

    return shapes;
}

// paths is an array of objects which contains a cells array which contains the 2d coordinates of the cells
// create a function which removes all the cells from paths based on coordinate passed

function removeFromPaths(paths, x, y) {
    const newPaths = paths.map((path) => {
        const newCells = path.cells.filter(
            (cell) => cell[0] !== x || cell[1] !== y,
        );
        return { ...path, cells: newCells };
    });

    return newPaths;
}

function getParsedString(svgString, cells, paths) {
    const parsedDrawingInfo = parseSvg(svgString);
    let newCells = cells;
    if (parsedDrawingInfo?.length < cells?.length) {
        for (let i = parsedDrawingInfo.length; i < cells.length; i++) {
            let cellExists = newCells[i];
            if (cellExists) {
                cellExists["hidden"] = true;
                newCells[i] = cellExists;
            }
            if (paths) {
                paths = removeFromPaths(paths, 0, i);
            }
        }
    }
    if (parsedDrawingInfo?.length > cells?.length) {
        for (let i = cells.length; i < parsedDrawingInfo.length; i++) {
            let newCellItem = JSON.parse(JSON.stringify(DEFAULT_CELL));
            newCellItem["fillColor"].enabled = true;
            newCells.push(newCellItem);
        }
    }
    parsedDrawingInfo.forEach((item, key) => {
        let cellExists = newCells[key];
        if (cellExists) {
            cellExists["drawing"] = JSON.parse(JSON.stringify(item.drawing));
            cellExists["hidden"] = false;
            newCells[key] = cellExists;
        }
    });
    return { newCells, paths };
}

export default getParsedString;
