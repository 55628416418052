export function calculateColumnWidths(table) {
    // Build a matrix representation of the table
    const tableMatrix = buildTableMatrix(table);

    // Find minimal colspans at each column index
    const columnGroups = buildColumnGroupsFromMinimalColspans(tableMatrix);

    const columnWidths = [];

    for (let group of columnGroups) {
        let width = getGroupWidth(tableMatrix, group.startCol, group.colspan);
        columnWidths.push(width);
    }

    return columnWidths;
}

function buildTableMatrix(table) {
    const matrix = [];
    const rows = table.rows;
    const skipMap = {}; // Keep track of columns to skip due to rowspan

    let maxColumns = 0;

    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
        const row = rows[rowIndex];
        const cells = row.cells;
        let colIndex = 0;

        if (!matrix[rowIndex]) {
            matrix[rowIndex] = [];
        }

        for (let cellIndex = 0; cellIndex < cells.length; cellIndex++) {
            // Skip columns that are spanned by rowspan from previous rows
            while (skipMap[colIndex]) {
                skipMap[colIndex]--;
                if (skipMap[colIndex] === 0) {
                    delete skipMap[colIndex];
                }
                colIndex++;
            }

            const cell = cells[cellIndex];

            // Skip cells with display: none
            if (isCellHidden(cell)) {
                continue;
            }

            const colspan = parseInt(cell.getAttribute("colspan")) || 1;
            const rowspan = parseInt(cell.getAttribute("rowspan")) || 1;

            // Place the cell in the matrix
            matrix[rowIndex][colIndex] = {
                cell: cell,
                merge: colspan > 1 || rowspan > 1,
                mergeHidden: false,
                width: cell.offsetWidth / colspan, // Adjust width for colspan
                colspan: colspan,
                rowspan: rowspan,
            };

            // Update maxColumns if necessary
            const totalColumns = colIndex + colspan;
            if (totalColumns > maxColumns) {
                maxColumns = totalColumns;
            }

            // Set skipMap for rowspan
            if (rowspan > 1) {
                for (let i = 0; i < colspan; i++) {
                    skipMap[colIndex + i] = rowspan - 1;
                }
            }

            // Mark mergeHidden cells in the matrix for colspan
            for (let i = 1; i < colspan; i++) {
                matrix[rowIndex][colIndex + i] = {
                    cell: null,
                    merge: false,
                    mergeHidden: true,
                    width: 0,
                };
            }

            colIndex += colspan;
        }

        // After processing all cells in the row, process any remaining skips
        while (colIndex < maxColumns) {
            if (skipMap[colIndex]) {
                skipMap[colIndex]--;
                if (skipMap[colIndex] === 0) {
                    delete skipMap[colIndex];
                }
            }
            colIndex++;
        }
    }

    // Attach maxColumns to the matrix for later use
    matrix.maxColumns = maxColumns;

    return matrix;
}

function buildColumnGroupsFromMinimalColspans(matrix) {
    const columnGroups = [];
    const maxColumns = matrix.maxColumns;

    // Initialize minimal colspans array
    const minColspans = new Array(maxColumns).fill(Infinity);

    // For each column index, find the minimal colspan starting at that index
    for (let colIndex = 0; colIndex < maxColumns; colIndex++) {
        for (let rowIndex = 0; rowIndex < matrix.length; rowIndex++) {
            const row = matrix[rowIndex];
            if (!row) continue;

            const cellObj = row[colIndex];
            if (!cellObj || cellObj.mergeHidden || isCellObjHidden(cellObj))
                continue;

            if (
                cellObj.colspan >= 1 &&
                cellObj.colspan < minColspans[colIndex]
            ) {
                minColspans[colIndex] = cellObj.colspan;
            }
        }

        // If no cell was found at this index, set colspan to 1
        if (minColspans[colIndex] === Infinity) {
            minColspans[colIndex] = 1;
        }
    }

    // Group the colspans to form column groups
    let i = 0;
    while (i < maxColumns) {
        const colspan = minColspans[i];
        columnGroups.push({ startCol: i, colspan: colspan });
        i += colspan;
    }

    // Verify that the sum of colspans equals maxColumns
    const totalColspan = columnGroups.reduce(
        (sum, group) => sum + group.colspan,
        0,
    );
    if (totalColspan !== maxColumns) {
        // Adjust if necessary
        // For simplicity, we can set all colspans to 1
        columnGroups.length = 0;
        for (let i = 0; i < maxColumns; i++) {
            columnGroups.push({ startCol: i, colspan: 1 });
        }
    }

    return columnGroups;
}

function getGroupWidth(matrix, startCol, colspan) {
    let widths = [];

    for (let rowIndex = 0; rowIndex < matrix.length; rowIndex++) {
        const row = matrix[rowIndex];
        if (!row) continue;

        const cellObj = row[startCol];
        if (!cellObj || cellObj.mergeHidden || isCellObjHidden(cellObj))
            continue;

        if (cellObj.colspan >= colspan) {
            // Cell spans this group
            widths.push(cellObj.width * colspan);
        }
    }

    // Remove zero widths
    widths = widths.filter((width) => width > 0);

    if (widths.length === 0) {
        widths = getAllWidths(matrix).map((width) => width * colspan);
    }

    return Math.min(...widths);
}

function isCellHidden(cell) {
    // Check if the cell or any of its parents have display: none
    if (cell.offsetWidth === 0 && cell.offsetHeight === 0) {
        return true;
    }
    // Alternatively, check the computed style
    const style = window.getComputedStyle(cell);
    return style.display === "none" || style.visibility === "hidden";
}

function isCellObjHidden(cellObj) {
    // Check if the cell object refers to a hidden cell
    if (!cellObj.cell) return true;
    return isCellHidden(cellObj.cell);
}

function getAllWidths(matrix) {
    const widths = [];
    for (let row of matrix) {
        for (let cellObj of row) {
            if (cellObj && cellObj.width > 0 && !isCellObjHidden(cellObj)) {
                widths.push(cellObj.width);
            }
        }
    }
    return widths;
}
