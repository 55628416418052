import React, { useEffect } from "react";
import Cell from "./Cell";
import { calculateColumnWidths } from "./utils/getDimensions";

export const TableRowLabels = ({ tableData }) => {
    const [firstTableRowWidths, setFirstTableRowWidths] = React.useState([]);
    const [allRowsHeights, setAllRowsHeights] = React.useState([]);
    const tableBody = document.getElementById("table-preview-renderer-body");
    // force rerender on tableData change
    useEffect(() => {
        let tempFirstTableRowWidths = [];
        let tempAllRowsHeights = [];
        const rowElem = tableBody?.childNodes[0];
        rowElem?.childNodes?.forEach((cell) => {
            tempFirstTableRowWidths.push(cell.offsetWidth);
        });

        tempFirstTableRowWidths = calculateColumnWidths(tableBody);

        tableBody?.childNodes?.forEach((row) => {
            const rowHeight = row.offsetHeight;
            tempAllRowsHeights.push(rowHeight);
        });

        setFirstTableRowWidths(tempFirstTableRowWidths);
        setAllRowsHeights(tempAllRowsHeights);
    }, [tableData]);

    return (
        <>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: firstTableRowWidths
                        .map((width) => `${width}px`)
                        .join(" "),
                    columnGap: tableData.gap?.horizontal ?? 0,
                    height: "20px",
                    position: "relative",
                    marginBottom: "10px",
                }}
            >
                {firstTableRowWidths.map((width, index) => {
                    return (
                        <p
                            key={index}
                            style={{ textAlign: "center", fontSize: "14px" }}
                        >
                            {width}px
                        </p>
                    );
                })}
                <div
                    style={{
                        position: "absolute",
                        left: -80 - (tableData.gap?.horizontal ?? 0),
                        top: 30 + (tableData.gap?.vertical ?? 0),
                        width: "100px",
                        display: "grid",
                        gridTemplateRows: allRowsHeights
                            .map((height) => `${height}px`)
                            .join(" "),
                        rowGap: tableData.gap?.vertical ?? 0,
                    }}
                >
                    {allRowsHeights.map((height, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    display: "grid",
                                    placeItems: "center",
                                }}
                            >
                                {height}px
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

const TableRowComponent = ({ rowProps, rowIndex, row }) => {
    return (
        <tr key={rowIndex} id={`table-row-${rowIndex}`}>
            {row.map((cell, cellIndex) => {
                return (
                    <Cell
                        cell={cell}
                        rowIndex={rowIndex}
                        cellIndex={cellIndex}
                        {...rowProps}
                        key={cellIndex}
                    />
                );
            })}
        </tr>
    );
};

export default TableRowComponent;
