import { Alert, Button, Form, Modal, Switch, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { clone } from "lodash";

const { Text } = Typography;

const PublishModal = (props: any) => {
    const { blocks, disableWrite, publishWorksheet, isRead } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [levelReset, setLevelReset] = useState(blocks?.map(() => false));
    const [
        shouldPublishToSecondaryHasuraEndpoints,
        setShouldPublishToSecondaryHasuraEndpoints,
    ] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        publishWorksheet({
            levelReset,
            shouldPublishToSecondaryHasuraEndpoints,
        });
        setShouldPublishToSecondaryHasuraEndpoints(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        setLevelReset(blocks?.map(() => false));
    }, [isModalOpen]);

    return (
        <>
            <Button
                style={{
                    marginTop: "10px",
                }}
                type="primary"
                block
                disabled={disableWrite}
                onClick={showModal}
            >
                Publish
            </Button>
            <Modal
                title="Publish Worksheet"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Publish Worksheet"
                destroyOnClose
                width={500}
            >
                <Text>Reset user submissions</Text>
                {isRead && (
                    <Alert
                        type="warning"
                        description="You don't have edit access currently!"
                    />
                )}
                <div
                    style={{
                        marginTop: "20px",
                    }}
                />
                {levelReset.map((block: any, idx: any) => (
                    <Form.Item label={`Reset for Level ${idx + 1} `} key={idx}>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={block}
                            onChange={(value) => {
                                let tmpBlock = clone(levelReset);
                                tmpBlock[idx] = value;
                                setLevelReset(tmpBlock);
                            }}
                        />
                    </Form.Item>
                ))}

                <div
                    style={{
                        marginTop: "20px",
                    }}
                />
                <Form.Item
                    label={`Publish to other servers`}
                    key={`publish_other_servers`}
                >
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={shouldPublishToSecondaryHasuraEndpoints}
                        onChange={(value) => {
                            setShouldPublishToSecondaryHasuraEndpoints(value);
                        }}
                    />
                </Form.Item>
            </Modal>
        </>
    );
};

export default PublishModal;
