import { useAuth0 } from "@auth0/auth0-react";

import {
    CopyIcon,
    Field,
    RenderCheckBox,
    RenderDynamicField,
    RenderField,
    RenderSelect,
    RenderUpload,
} from "src/components";
import FormikPersist from "src/components/FormikPersist";
import { TagsAssignComponent } from "src/modules/book/components";
import { Can } from "src/services/casl";
import { Button, Col, Form, message, Row, Select, Space } from "antd";
import { FieldArray, withFormik } from "formik";
import { useRouter } from "src/helpers";
import React, { useEffect, useState } from "react";
import * as yup from "yup";

const Option = Select.Option;

const subjects = [
    "Maths",
    "Science",
    "Physics",
    "Biology",
    "Chemistry",
    "Environmental Studies",
    "English",
    "Social Studies",
    "Social Science",
    "Hindi",
];

const classNames = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
];

const buttonsList = [
    {
        type: "next_question",
        text: "Next question",
        label: "Next question",
    },
    {
        type: "show_story",
        text: "Show Hint",
        label: "Show Story",
    },
    {
        type: "show_submit",
        text: "show_submit",
        label: "Show Submit",
    },
    {
        type: "show_try_again",
        text: "Show Try Again",
        label: "Show Try Again",
    },
];

const textType = [
    {
        type: "normal",
        label: "Normal",
    },
    {
        type: "function",
        label: "Function",
    },
];

const WorksheetForm = (props: any) => {
    const {
        onFinishFailed,
        values,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetValues,
        toggleResetValues,
        resetForm,
        disableStatus = false,
        worksheet,
    } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (resetValues) {
            resetForm();
            toggleResetValues(false);
        }
    }, [resetValues]);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                {[
                    "personalized_learning_v3",
                    "personalized_learning_v4",
                ].includes(values.type) ? (
                    <>
                        <Col lg={12} md={24}>
                            <Field
                                label="Worksheet Title"
                                placeholder="Worksheet Title"
                                name={"title"}
                                icon="FontSizeOutlined"
                                component={RenderField}
                                type="text"
                                value={values.title}
                                rules={[{ required: true }]}
                                path="worksheet/"
                            />
                        </Col>
                        <Col lg={12} md={24}>
                            <Field
                                label="Slug"
                                placeholder="Add a slug"
                                name={"slug"}
                                icon="FontSizeOutlined"
                                component={RenderField}
                                type="text"
                                value={values.slug}
                                path="worksheet/"
                            />
                        </Col>
                    </>
                ) : (
                    <Col lg={24} md={24}>
                        <Field
                            label="Worksheet Title"
                            placeholder="Worksheet Title"
                            name={"title"}
                            icon="FontSizeOutlined"
                            component={RenderField}
                            type="text"
                            value={values.title}
                            rules={[{ required: true }]}
                            path="worksheet/"
                        />
                    </Col>
                )}

                <Col lg={24} md={24}>
                    <Field
                        label="Worksheet Description"
                        placeholder="Worksheet Description"
                        name={"description"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.description || ""}
                        rules={[{ required: true }]}
                        path="worksheet/"
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Worksheet Banner"
                        placeholder="Worksheet Banner"
                        name={"banner"}
                        icon="FontSizeOutlined"
                        component={RenderUpload}
                        type="textarea"
                        value={values.banner}
                        rules={[{ required: true }]}
                        path="worksheet/"
                        setLoad={setLoading}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Unlock all Levels"
                        placeholder="Unlock all Levels"
                        name={"other.unlock_levels"}
                        icon="FontSizeOutlined"
                        component={RenderCheckBox}
                        checked={values.other.unlock_levels}
                        rules={[{ required: true }]}
                    />
                    <Field
                        label="Worksheet is locked?"
                        placeholder="Worksheet is locked?"
                        name={"other.is_locked"}
                        icon="FontSizeOutlined"
                        component={RenderCheckBox}
                        checked={values.other.is_locked}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Book Subject"
                        placeholder="Book Subject"
                        name={"subject"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.subject}
                        rules={[{ required: true }]}
                        disabled={true}
                    >
                        {subjects.map((subject) => (
                            <Option value={subject}>{subject}</Option>
                        ))}
                    </Field>
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Book Class"
                        placeholder="Book Class"
                        name={"class"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.class}
                        rules={[{ required: true }]}
                        disabled={true}
                    >
                        {classNames.map((className) => (
                            <Option value={className}>{className}</Option>
                        ))}
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <TagsAssignComponent
                        name="tags"
                        formik={props}
                        isSet={true}
                        handleChange={(data) => {
                            setFieldValue(
                                ["tags", "data"],
                                data.map((d) => ({
                                    tag_id: d.value,
                                    label: d.label,
                                })),
                            );
                        }}
                        selected={
                            values?.tags?.data?.map((tag, index) => ({
                                value: tag.tag_id,
                                key: index,
                                label: tag.label,
                            })) || []
                        }
                        tagGroup={"worksheet"}
                    />
                    <br />
                </Col>

                <Col lg={24} md={24}>
                    <FieldArray
                        name={"other.ratings"}
                        render={(arrayHelpers) => (
                            <RenderDynamicField
                                buttonText="Add Ratings"
                                keys={[
                                    {
                                        key: "user_name",
                                        type: "text",
                                        label: "User Name",
                                    },
                                    {
                                        key: "user_description",
                                        type: "text",
                                        label: "User Description",
                                    },
                                    {
                                        key: "rating",
                                        type: "number",
                                        label: "Rating",
                                    },
                                    {
                                        key: "comment",
                                        type: "textarea",
                                        label: "Comment",
                                    },
                                    {
                                        key: "avatar",
                                        type: "text",
                                        label: "Avatar",
                                    },
                                ]}
                                arrayHelpers={arrayHelpers}
                                values={values.other.ratings}
                                name={"other.ratings"}
                            />
                        )}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Worksheet Type"
                        placeholder="Worksheet Type"
                        name={"type"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.type}
                        rules={[{ required: true }]}
                        disabled={!!worksheet?.id}
                    >
                        <Option value={"normal"}>{"Normal Worksheet"}</Option>
                        <Option value={"timed"}>{"Timed Worksheet"}</Option>
                        <Option value={"personalized_learning"}>
                            {"Personalized Learning Worksheet"}
                        </Option>
                        <Option value={"personalized_learning_v2"}>
                            {"Personalized Learning Worksheet V2"}
                        </Option>
                        <Option value={"personalized_learning_v3"}>
                            {"Personalized Learning Worksheet V3"}
                        </Option>
                        <Option value={"personalized_learning_v4"}>
                            {"Personalized Learning Worksheet V4"}
                        </Option>
                        <Option value={"quiz_form"}>{"Quiz Form"}</Option>
                    </Field>
                </Col>

                {[
                    "personalized_learning_v2",
                    "personalized_learning_v3",
                    "personalized_learning_v4",
                ].includes(values.type) && (
                    <>
                        <Col lg={8} md={24}>
                            <Field
                                label="Homepage Banner"
                                placeholder="Homepage Banner"
                                name={"other.homepage_banner"}
                                icon="FontSizeOutlined"
                                component={RenderUpload}
                                value={values.other.homepage_banner}
                                rules={[{ required: true }]}
                                path="worksheet/"
                                setLoad={setLoading}
                            />
                        </Col>

                        <Col lg={8} md={24}>
                            <Field
                                label="Worksheet Duration Text"
                                placeholder="Worksheet Duration Text"
                                name={"other.duration_text"}
                                icon="FontSizeOutlined"
                                component={RenderField}
                                type="text"
                                value={values.other?.duration_text || ""}
                                rules={[{ required: true }]}
                                path="worksheet/"
                            />
                        </Col>

                        <Col lg={8} md={24}>
                            <Field
                                label="Pass Threshold"
                                placeholder="Pass Threshold"
                                name={"other.pass_threshold"}
                                icon="FontSizeOutlined"
                                component={RenderField}
                                type="number"
                                max={100}
                                min={0}
                                value={values.other?.pass_threshold || "0"}
                                rules={[{ required: true }]}
                                path="worksheet/"
                            />
                        </Col>

                        {[
                            "personalized_learning_v3",
                            "personalized_learning_v4",
                        ].includes(values.type) && (
                            <>
                                <Col
                                    lg={24}
                                    md={24}
                                    style={{
                                        border: "1px solid black",
                                    }}
                                >
                                    <Space>
                                        <h3>Configure Question Buttons</h3>
                                        <Button
                                            shape="circle"
                                            type={"primary"}
                                            size="small"
                                            icon={<CopyIcon />}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                message.info(
                                                    `Copied Config to clipboard!`,
                                                );
                                                navigator.clipboard.writeText(
                                                    JSON.stringify(
                                                        values.other
                                                            .buttons_list,
                                                    ),
                                                );
                                            }}
                                        />
                                        <Button
                                            type="primary"
                                            size="small"
                                            onClick={async () => {
                                                try {
                                                    const clipboardData =
                                                        await navigator.clipboard.readText();
                                                    if (!clipboardData) return;
                                                    let data =
                                                        JSON.parse(
                                                            clipboardData,
                                                        );
                                                    if (!Array.isArray(data))
                                                        return;
                                                    setFieldValue(
                                                        [
                                                            "other",
                                                            "buttons_list",
                                                        ],
                                                        data,
                                                    );
                                                } catch (e) {}
                                            }}
                                        >
                                            Click to paste config
                                        </Button>
                                    </Space>
                                    <FieldArray
                                        name={"other.buttons_list"}
                                        render={(arrayHelpers) => (
                                            <RenderDynamicField
                                                buttonText="Add Button"
                                                keys={[
                                                    {
                                                        key: "type",
                                                        type: "select",
                                                        label: "Type",
                                                        options:
                                                            buttonsList?.map(
                                                                (v) => ({
                                                                    value: v.type,
                                                                    label: v.label,
                                                                }),
                                                            ),
                                                    },
                                                    {
                                                        key: "textType",
                                                        type: "select",
                                                        label: "Text Type",
                                                        options: textType?.map(
                                                            (v) => ({
                                                                value: v.type,
                                                                label: v.label,
                                                            }),
                                                        ),
                                                    },
                                                    {
                                                        key: "text",
                                                        type: "text",
                                                        label: "Text",
                                                    },
                                                    {
                                                        key: "function",
                                                        type: "textarea",
                                                        label: "Function",
                                                    },
                                                ]}
                                                arrayHelpers={arrayHelpers}
                                                values={
                                                    values.other.buttons_list
                                                }
                                                name={"other.buttons_list"}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col
                                    lg={24}
                                    md={24}
                                    style={{ marginTop: "20px" }}
                                >
                                    <Field
                                        label="Next Worksheet Function"
                                        placeholder="Next Worksheet Function"
                                        name={"other.next_worksheet"}
                                        icon="FontSizeOutlined"
                                        component={RenderField}
                                        type="textarea"
                                        value={
                                            values.other?.next_worksheet || ""
                                        }
                                        rules={[{ required: true }]}
                                        path="worksheet/"
                                    />
                                </Col>
                            </>
                        )}
                    </>
                )}

                <Col lg={24} md={24}>
                    <Can I="publish" a={"worksheet"} passThrough>
                        {(allowed: boolean) => (
                            <Field
                                label="Worksheet Status"
                                placeholder="Worksheet Status"
                                name={"status"}
                                icon="FontSizeOutlined"
                                component={RenderSelect}
                                type="text"
                                value={values.status}
                                rules={[{ required: true }]}
                                disabled={
                                    !allowed ||
                                    disableStatus ||
                                    values.status !== "active"
                                }
                            >
                                <Option value="inactive">Inactive</Option>
                                <Option value="active">Active</Option>
                            </Field>
                        )}
                    </Can>
                </Col>

                <Col lg={12} md={24}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isSubmitting || loading}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export const FormSchema = yup.object().shape({
    title: yup.string().required("This field is required"),
    banner: yup.string().required("This field is required"),
    class: yup.string().required("This field is required"),
    subject: yup.string().required("This field is required"),

    status: yup.string().required("This field is required"),
});

const WorksheetFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { worksheet, user_id, subject, className } = props;
        return {
            ...(worksheet?.id ? { id: worksheet.id } : {}),
            title: worksheet?.title || "",
            description: worksheet?.description || "",
            banner: worksheet?.banner || "",
            class: worksheet?.class || className || "",
            subject: worksheet?.subject || subject || "",

            status: worksheet?.status || "inactive",
            user_id,
            tags: {
                data:
                    worksheet?.tags.map(({ tag_id }: any) => ({
                        tag_id,
                    })) || [],
            },
            other: {
                ratings: worksheet?.other?.ratings || [],
                sub_title: worksheet?.other?.sub_title || "",
                pass_threshold: worksheet?.other?.pass_threshold || "0",
                homepage_banner: worksheet?.other?.homepage_banner || "",
                duration_text: worksheet?.other?.duration_text || "",
                is_locked: worksheet?.other
                    ? worksheet?.other?.is_locked
                    : true,
                unlock_levels: worksheet?.other
                    ? worksheet?.other?.unlock_levels
                    : false,
                buttons_list: worksheet?.other?.buttons_list || [],
                next_worksheet: worksheet?.other?.next_worksheet || "",
            },
            ...([
                "personalized_learning_v3",
                "personalized_learning_v4",
            ].includes(worksheet?.type)
                ? {
                      slug:
                          worksheet?.slug ||
                          (worksheet?.id ? `${worksheet?.id}` : null),
                  }
                : {}),
            type: worksheet?.type || "personalized_learning_v4",
        };
    },
    validationSchema: FormSchema,

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish({
            ...values,
            tags: {
                data: values.tags.data.map(({ tag_id }: any) => ({ tag_id })),
            },
        });
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

const FormikForm = WorksheetFormWithFormik(WorksheetForm);

const FormikFormWithUser = (props) => {
    const { user } = useAuth0();

    const {
        query: { subject, class: className },
    } = useRouter();

    const namespace = "https://hasura.io/jwt/claims/";
    const user_id = user && user[namespace + "user_id"];
    return (
        <FormikForm
            {...props}
            user_id={user_id}
            className={className}
            subject={subject}
        />
    );
};

export default FormikFormWithUser;
