import React from "react";
import Cell from "./Cell";
import { shapeGenerator } from "./utils/drawing";

const DrawingComponent = ({ tableChildProps }: any) => {
    const { tableData } = tableChildProps;
    const drawing = tableData.drawing;
    const cells = tableData.cells[0];
    return (
        <div
            style={{
                width: drawing?.drawingAreaDimensions?.width || "100%",
                height: drawing?.drawingAreaDimensions?.height || "500px",
                position: "relative",
            }}
        >
            {cells.map((cell, index) => {
                const drawingPosition = cell?.drawing?.position;
                const positionStyles = {
                    position: "absolute",
                    top: drawingPosition?.y || 0,
                    left: drawingPosition?.x || 0,
                };
                const dimension = cell?.drawing?.dimension;
                const customStyles = {
                    shapeStyles: shapeGenerator(
                        cell?.drawing?.shape?.type,
                        cell?.drawing?.shape?.path,
                    ),
                    displayStyles: {
                        display: "grid",
                        placeItems: "center",
                    },
                    positionStyles,
                    sizeStyles: {
                        width: dimension?.width,
                        height: dimension?.height,
                        minWidth: dimension?.width,
                        minHeight: dimension?.height,
                    },
                };
                return (
                    <Cell
                        cell={cell}
                        rowIndex={0}
                        cellIndex={index}
                        {...tableChildProps}
                        key={index}
                        reorderedCellIndex={index}
                        reorderedRowIndex={0}
                        isDrawing={true}
                        customStyles={customStyles}
                    />
                );
            })}
        </div>
    );
};

export default DrawingComponent;
